import React from "react";
import { Carousel } from "@material-tailwind/react";

const Gallary = () => {
  const images_num = 15;

  const images = Array.from(
    { length: images_num },
    (_, i) => `gall-${i + 1}.jpg`
  );

  return (
    <section id="courses" className="bg-gray-300 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
          <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
            Dynamic Acting Studio
          </h2>
          <h1 class="sm:text-3xl text-3xl font-extrabold title-font mb-4 text-gray-900">
            GALLERY
          </h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-center">
            Here are some snaps of the life and experiences @Dynamic Acting
            Studio
          </p>
        </div>
        <div className="flex flex-wrap mx-6 -mb-10 text-justify ">
          <Carousel
            className="rounded-xl "
            transition={{ duration: 1 }}
            autoplay={true}
            autoplayDelay={2000}
            loop={true}
          >
            {images.map((value, index) => (
              <img
                key={index}
                src={`assets/${value}`}
                alt="gallary image"
                className="h-[600px] w-full object-contain"
              />
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Gallary;
