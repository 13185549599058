import React from 'react'

const ContactForm = () => {
  return (
    <section class="text-gray-600 body-font relative">
      <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-col text-center w-full mb-20">
        
          <h1 class="sm:text-3xl text-3xl font-extrabold title-font mb-4 text-gray-900">
            HAVE QUESTIONS?
          </h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
          Give us a call or send the form for a call-back.
          </p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <form action="mailto:info@dynamicactingstudio.com" method='post' encType='text/plain'>

          <div className="flex flex-wrap -m-2">
            <div className="p-2 w-1/2">
              <div className="relative">
                <label for="name" className="leading-7 text-sm text-gray-600">
                  First Name
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label for="name" className="leading-7 text-sm text-gray-600">
                  Last Name
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label for="email" className="leading-7 text-sm text-gray-600">
                  Email*
                </label>
                <input
                required
                  type="email"
                  
                  id="email"
                  name="email"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label for="mobile" className="leading-7 text-sm text-gray-600">
                  Mobile Number*
                </label>
                <input
                  required
                  type="tel"
                  pattern='[0-9]*'
                  id="mobile"
                  name="mobile"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div class="p-2 w-full">
          <div class="relative">
            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
            <textarea id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
        </div>
            <div className="flex p-2 w-full">
              <button className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                Email
              </button>
              <button className="flex mx-auto text-white bg-deep-orange-800 border-0 py-2 px-8 focus:outline-none hover:bg-deep-orange-600 rounded text-lg">
                Message
              </button>
            </div>
            
          </div>
          </form>
          
        </div>
      </div>
    </section>
  );
}

export default ContactForm