import React from "react";
import DisplayTabs from "../components/DisplayTabs";

const CoursesInfo = () => {
  return (
    <section id='courses' className="bg-gray-200 body-font">
      <div className="container px-5 lg:py-24 py-20 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
          <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
            Dynamic Acting Studio
          </h2>
          <h1 class="sm:text-3xl text-3xl font-extrabold title-font mb-4 text-gray-900">
            COURSES WE OFFER
          </h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-justify">
            Take your acting skills to new heights with our carefully crafted
            courses. Our 3-month and 6-month programs are designed to provide
            you with a well-rounded understanding of the acting industry.
            Through a dynamic curriculum that covers character development,
            improvisation, voice modulation, and more, you'll gain the essential
            knowledge and skills needed to excel in your acting career.
          </p>
        </div>
        <div className="flex flex-wrap lg:mx-6 sm:mx-2 -mb-10 text-justify ">
          <div className="sm:w-1/2 mb-10 px-4">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-contain object-center h-full w-full"
                src="assets/poster.png"
              />
            </div>
            <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">
              Diploma in Screen Acting
            </h2>
            <p className="leading-relaxed text-sm">
              Take your acting skills to new heights with our carefully crafted
              courses. Our 3-month and 6-month programs are designed to provide
              you with a well-rounded understanding of the acting industry.
              Through a dynamic curriculum that covers character development,
              improvisation, voice modulation, and more, you'll gain the
              essential knowledge and skills needed to excel in your acting
              career.
            </p>
            {/* <button className="flex mx-auto mt-6 text-white bg-indigo-500 border-0 py-2 px-5 focus:outline-none hover:bg-indigo-600 rounded">
              Button
            </button> */}
          </div>
          <div className="sm:w-1/2 mb-10 px-4">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-contain object-center h-full w-full"
                src="assets/certificate-thumb.jpg"
              />
            </div>
            <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">
              Certificate in Acting
            </h2>
            <p className="leading-relaxed text-base">
              An introduction to acting and theatre, with lots of personal
              development. 3 months, part-time Weekend Course. In Mumbai and
              Delhi.
            </p>
            {/* <button className="flex mx-auto mt-6 text-white bg-indigo-500 border-0 py-2 px-5 focus:outline-none hover:bg-indigo-600 rounded">
              Button
            </button> */}
          </div>
        </div>
        <div className="flex flex-col text-center w-[75%] mx-auto my-20">
          <h1 className="title-font text-3xl font-medium text-gray-900 mt-6 mb-9">
            Upcoming Course Openings
          </h1>

          <DisplayTabs />
        </div>
      </div>
    </section>
  );
};

export default CoursesInfo;
