import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Error from "./pages/Error";
import Project from "./components/Project";

function App() {
  return (
    <div className="App">
      <NavBar />
      {/* Defining routes path and rendering components as element */}
      <Routes>
        <Route strict path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/project/:id" element={<Project/>} />

        <Route path="*" element={<Error/>} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
