import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
 
const DisplayTabs = ()=> {
  const data = [
    {
      label: "Diploma in Screen Acting",
      value: "diploma",
      rows: [ "31 July 2023 (4 months) - Mumbai" ,"Full time, Monday-Friday 9 AM - 5 PM"],
    },
    {
      label: "Certificate in Acting",
      value: "certificate",
      rows: [ "Mumbai: 8 July  2023" ,"All Weekend Courses"],
    },
    
  ];
  
  const [activeTab, setActiveTab] = React.useState(data[0].value);
  
  return (
    <Tabs value={activeTab} >
      <TabsHeader
        className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
        indicatorProps={{
          className: "bg-transparent border-b-2 border-blue-500 shadow-none rounded-none",
        }}
      >
        {data.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? "text-blue-500" : ""}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, rows }) => (
          <TabPanel key={value} value={value}>
            {rows.map((row,index)=><p className="text-left lg:text-lg sm:text-sm font-bold" key={index}>{row}</p>)}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default DisplayTabs;