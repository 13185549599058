import React from "react";
import Hero from "../components/Hero"
import ContactForm from "../components/ContactForm";
import Intro from "../components/Intro";
import CoursesInfo from "../components/CoursesInfo"
import Gallary from "../components/Gallary";

const Home = () => {
  return (
    <>
    <Hero/>
    <Intro/>
    <CoursesInfo/>
    <Gallary/>
    <ContactForm/>
    </>
  );
};

export default Home;
