import React from "react";

import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Intro = () => {
  return (
    <>
      <section className="bg-gray-200 body-font">
        <div className="container px-5 pt-24 pb-10 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
              Dynamic Acting Studio
            </h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Learn, Apply, Succeed: Real-World Training
            </h1>
            <img className="w-full lg:h-[600px] sm:h-[400px] object-contain my-6" src="assets/home_poster.jpg" alt="home poster" />
        
            <p className="lg:w-2/3 mx-auto leading-relaxed lg:text-lg text-sm text-justify">
              Dynamic Acting Studio is a unique training center in India,
              offering exceptional training experiences for aspiring actors. Led
              by experienced instructors, Megh varn Pant and Avinash Soni, who
              have trained at the prestigious Film and Television Institute of
              India (FTII), our studio stands out as the only one in the country
              where FTII instructors provide regular classes. We also
              collaborate with instructors from NSD Delhi to ensure
              comprehensive training for our students across different mediums,
              including on-camera acting and stage performance. In addition to
              our courses, we offer opportunities to be cast in short films and
              theater productions, preparing you for auditions and providing
              valuable guidance from actors and casting directors. If you're
              interested in screen acting, contact us to learn more about our
              upcoming 3-month and 6-month courses.
            </p>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
            <h1 class="flex-grow sm:pr-16 lg:text-2xl text-xl font-medium title-font text-gray-900">
            Step Into the Spotlight: Ignite Your Acting Journey
            </h1>
            <Link to="contact" className="text-black">
              <Button
                size="md"
                className="bg-secondary  my-4 text-bold flex mb-4"
              >
                  ENROLL NOW
                
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Intro;
