import React from "react";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-4xl font-medium title-font mb-4 text-black">
            Contact Us
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-lg pb-4">
          Step Into the Spotlight: Ignite Your Acting Journey
          </p>
          <img className="w-full lg:h-[600px] sm:h-[300px] object-contain" src="assets/contact.jpg" alt="contact poster" />
        </div>
        <ContactForm/>
        <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
              <a className="text-indigo-500">info@dynamicactingstudio.com</a>
              <p className="leading-normal my-5">
               147 Rudra Stueio, Aaram nagar part2,7 bunglow
                <br />
                Andheri west, Mumbai
              </p>
            </div>
      </div>
    </section>
  );
};

export default Contact;
