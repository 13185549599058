import React from "react";

const About = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-20 mx-auto flex flex-col">
        <div className="lg:w-4/6 mx-auto">
          
          <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
            <h1 class="sm:text-3xl text-4xl font-extrabold title-font mb-2 text-black">
              ABOUT US
            </h1>
            <div class="h-1 w-24 bg-indigo-500 rounded"></div>
          </div>
          <div className="w-full mt-12">
            <h2 class="text-2xl font-medium text-gray-900 title-font mb-2">
              Unleash Your Acting Potential with Dynamic Training
            </h2>
            <p class="leading-relaxed text-lg text-justify">
              Discover a world of exceptional training at Dynamic Acting Studio.
              Our experienced instructors from the renowned FTII bring their
              expertise and passion to help you unlock your true acting
              potential. Join us and embark on a transformative journey in the
              world of performing arts.
            </p>
          </div>
          <div className="rounded-lg  overflow-hidden">
            <img
              alt="content"
              className="object-cover object-center my-10 h-full w-full"
              src="assets/poster.png"
            />
          </div>
          <div className="w-full mt-12">
            <h2 class="text-2xl font-medium text-gray-900 title-font mb-2 text-right">
              From Stage to Screen: Master Every Medium{" "}
            </h2>
            <p class="leading-relaxed text-lg text-justify">
              At Dynamic Acting Studio, we understand that acting is a versatile
              art form. Our comprehensive training approach equips you with the
              skills and techniques needed to excel both on stage and in front
              of the camera. Whether you dream of captivating audiences in a
              theater production or leaving a lasting impression on screen, we
              have the tools to make your acting ambitions a reality.
            </p>
          </div>
          <div className="w-full mt-12">
            <h1 class="text-3xl font-medium text-gray-900 title-font mb-2">
              Course Offerings
            </h1>
            <h2 class="text-2xl font-medium text-gray-900 title-font mb-2">
              Elevate Your Acting Craft: Enroll Today!{" "}
            </h2>
            <p class="leading-relaxed text-lg text-justify">
              Take your acting skills to new heights with our carefully crafted
              courses. Our 3-month and 6-month programs are designed to provide
              you with a well-rounded understanding of the acting industry.
              Through a dynamic curriculum that covers character development,
              improvisation, voice modulation, and more, you'll gain the
              essential knowledge and skills needed to excel in your acting
              career.
            </p>
          </div>
          {/* <div className="flex flex-col sm:flex-row mt-10">
            <div className="w-full sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
              <p className="leading-relaxed text-lg mb-4">
                Meggings portland fingerstache lyft, post-ironic fixie man bun
                banh mi umami everyday carry hexagon locavore direct trade art
                party. Locavore small batch listicle gastropub farm-to-table
                lumbersexual salvia messenger bag. Coloring book flannel
                truffaut craft beer drinking vinegar sartorial, disrupt fashion
                axe normcore meh butcher. Portland 90's scenester vexillologist
                forage post-ironic asymmetrical, chartreuse disrupt butcher
                paleo intelligentsia pabst before they sold out four loko. 3
                wolf moon brooklyn.
              </p>
              <a className="text-indigo-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default About;
