import React from "react";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <section className="relative bg-hero bg-cover bg-center object-contain w-full h-[600px] dark:bg-gray-900">
      <div className="bg-black/50 absolute inset-0 grid h-full w-full place-items-center">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12  ">
          <h1 className="mb-4 text-3xl  font-extrabold tracking-tight leading-none text-myWhite md:text-5xl lg:text-6xl dark:text-white">
            Unleash Your Acting Potential with Dynamic Training{" "}
          </h1>
          <p className="mb-8 text-md font-normal text-white lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
            Unlock your true acting potential at Dynamic Acting Studio. With
            experienced FTII instructors, we empower you to master both stage
            and screen, becoming a confident, versatile actor ready to make your
            mark in the industry.
          </p>
          <div className="flex flex-col lg:mb-14 mt-8 space-y-6 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <Link to="contact">
              <Button
                size="md"
                className="bg-myWhite text-secondary  text-bold flex mb-4"
              >
                Get Started
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
