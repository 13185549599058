import React from "react";
import { Link } from "react-router-dom";

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="bg-gray-100 body-font">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img src="assets/logo.png" className="w-28 h-auto" alt="" />
            <span className="ml-2 text-xl">Dynamic Acting Studio</span>
          </a>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold text-gray-900 tracking-widest text-xl mb-3">
              DYNAMIC ACTING STUDIOS
            </h2>
            <nav className="list-none mb-10">
              <li>147 Rudra Stueio, Aaram nagar part2,7 bunglow</li>
              <li>Andheri west, Mumbai</li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold text-gray-900 tracking-widest text-lg  mb-3">
              EXPLORE
            </h2>
            <nav className="list-none mb-10">
              <li>
                <Link to="/about" className="text-gray-600 hover:text-gray-800">
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Contact
                </Link>
              </li>
            </nav>
          </div>

          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold text-gray-900 tracking-widest text-xl mb-3">
              OUR COURSES
            </h2>
            <nav className="list-none mb-10">
              <li>
                  Diploma in Screen Acting
              </li>
              <li>
                  Certificate in Acting
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-gray-150">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-black text-sm text-center sm:text-left">
            &copy; {currentYear} Dynamic Acting Studio. All Rights Reserved.
          </p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            <a className="text-black">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a className="ml-3 text-black">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a className="ml-3 text-black">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a
              className="ml-3 text-black"
              href="https://www.youtube.com/@DynamicActingStudio/videos"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M23.73 7.27c-.27-.98-1-1.77-1.98-2l-.44-.13C20.55 5 12 5 12 5s-8.55 0-9.31.14c-1 .23-1.71 1.02-1.98 2C.01 8.03 0 12 0 12s.01 3.97.71 4.73c.27.98 1 1.77 1.98 2l.44.13C3.45 20 12 20 12 20s8.55 0 9.31-.14c1-.23 1.71-1.02 1.98-2 .7-.76.71-4.73.71-4.73s-.01-3.97-.71-4.73zM9.5 15V9l5.5 3-5.5 3z"
                  fill="#ff0000"
                />
              </svg>
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
