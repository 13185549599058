import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
  
} from "@material-tailwind/react";

import { Link } from "react-router-dom";


import { Link as ScrollLink } from "react-scroll";

const NavBar = () => {
  const [openNav, setOpenNav] = React.useState(false);

  const navItems = [
    "Home",
    "About",
    "Contact"
  ]

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mb-4 mt-2 flex  flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <ScrollLink 
            className="flex  items-center mr-6 cursor-pointer text-secondary"
            to='courses'
            spy={true}
            smooth="easeInQuad"
            duration={800}>
              Courses
            </ScrollLink>
      {navItems.map((item, index) => (
        <Typography
          key={index}
          as="li"
          variant="lead"
          color="white"
          className="p-1 font-normal"
        >
          {item === "Home" ? (
            <Link to="/" className="flex  items-center mr-6 cursor-pointer text-secondary">
              {item}
            </Link>
          ) : (
            <Link to={`/${item.toLowerCase()}`} className="flex  items-center mr-6 cursor-pointer text-secondary">
            {item}
          </Link>
          )}
        </Typography>
      ))}
     
      
     
    </ul>
  );

  return (
    <>
      <Navbar className="sticky top-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4">
        <div className="flex items-center justify-between text-blue-gray-900">
          <Typography
            className="mr-4 cursor-pointer py-1.5 font-extrabold"
          >
            <Link to="/">
              DYNAMIC ACTING STUDIO
              </Link>
          </Typography>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">{navList}</div>
           
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <Collapse open={openNav}>
          {navList}
          
        </Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
